<script lang="ts">
import SFormLabel from "scuffed-ui/src/components/Form/SFormLabel/SFormLabel.vue";
import SHeading from "scuffed-ui/src/components/Heading/SHeading/SHeading.vue";
import SInputCheckbox from "scuffed-ui/src/components/Input/SInputCheckbox/SInputCheckbox.vue";
import { defineComponent, onBeforeMount, ref, watch } from "vue";

export default defineComponent({
  name: "UGameInfo",
  components: { SHeading, SInputCheckbox, SFormLabel },
  setup() {
    const isUpdating = ref(false);

    return {
      isUpdating,
    };
  },
});
</script>

<template>
  <section>
    <SHeading class="text-4xl" :level="1">Game Info</SHeading>

    <div class="flex flex-col">
      <SFormLabel>Is Updating?</SFormLabel>
      <SInputCheckbox class="h-[3.25rem] w-[3.23rem]" v-model="isUpdating" />
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
