import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full pr-6 gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UBroadcastToast = _resolveComponent("UBroadcastToast")!
  const _component_UBroadcastNotification = _resolveComponent("UBroadcastNotification")!
  const _component_UGameInfo = _resolveComponent("UGameInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UBroadcastToast),
    _createVNode(_component_UBroadcastNotification),
    _createVNode(_component_UGameInfo)
  ]))
}