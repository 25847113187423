<script lang="ts">
import { defineComponent } from "vue";
import UBroadcastToast from "./components/UBroadcastToast.vue";
import UGameInfo from "./components/UGameInfo.vue";
import UBroadcastNotification from "./components/UBroadcastNotification.vue";

export default defineComponent({
  name: "UAdminTools",
  components: {
    UBroadcastToast,
    UBroadcastNotification,
    UGameInfo,
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="flex flex-col w-full pr-6 gap-8">
    <UBroadcastToast />
    <UBroadcastNotification />
    <UGameInfo />
  </div>
</template>

<style lang="scss" scoped></style>
