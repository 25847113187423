import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeading = _resolveComponent("SHeading")!
  const _component_SFormLabel = _resolveComponent("SFormLabel")!
  const _component_SInputCheckbox = _resolveComponent("SInputCheckbox")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_SHeading, {
      class: "text-4xl",
      level: 1
    }, {
      default: _withCtx(() => [
        _createTextVNode("Game Info")
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SFormLabel, null, {
        default: _withCtx(() => [
          _createTextVNode("Is Updating?")
        ]),
        _: 1
      }),
      _createVNode(_component_SInputCheckbox, {
        class: "h-[3.25rem] w-[3.23rem]",
        modelValue: _ctx.isUpdating,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isUpdating) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}